<template>

  <div v-if="data">

    <Scatter :chart-options="chartOptions" :chart-data="data" :style="('position: relative; height:' + height + 'px; margin-bottom:10px')"/>

  </div>
</template>
<script>
import {defineComponent} from 'vue'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {Scatter} from 'vue-chartjs'
import ChartjsPluginWatermark from 'chartjs-plugin-watermark'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartjsPluginWatermark
)
export default defineComponent({
  name: "ChartRangeBar",
  components: {
    Scatter
  },
  props: ['data', 'max', 'height'],
  computed: {
    logopath() {
      const logo = require(`./../../assets/dacy.png`)
      let image = new Image();
      image.src = logo

      return logo
    }
  },
  methods: {
    ready() {
      this.loading = false
    },
    onBeforeMount() {
      this.loading = true
    }
  },
  data() {
    return {
      loading: false,
      chartOptions: {
        layout: {
          autoPadding: false,
          padding: 10
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                return "d" + context.label + ", " + context.dataset.label;
              }
            }
          }
        },
        elements: {
          point: {
            pointStyle: 'rect',
            radius: 10,
            hoverRadius: 12,
            backgroundColor: '#f88600'
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          drug: {
            type: 'category',
            position: 'left',
          },
          day: {
            max: this.max ? this.max : undefined,
            type: 'linear',
            position: 'bottom',
            offset: true,
            ticks: {
              stepSize: 1,
              callback: function(value) {
                return 'd' + value;
              }
            }
          }
        },
        watermark: {
          width:100,
          height:60,
          image: () => this.logopath,
          opacity: 0.6,
          alignX: "right",
          alignY: "center",
          alignToChartArea: false,
          position: "back",
        }
      }
    }
  }
})
</script>
<style scoped>

</style>
